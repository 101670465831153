/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/media-extends';

/* stylelint-disable declaration-no-important */

.Slider {
    &:not(.ProductGallery-Slider) {
        @include mobile {
            height: 500px !important;
        }
    }

    &-Crumbs {
        grid-template-columns: repeat(auto-fit, 10px);
        justify-content: center;
        inset-block-end: 30px;
    }

    &-Crumb {
        width: 5px;
        height: 5px;
        cursor: pointer;
        background-color: rgba(255, 255, 255, .8);
        will-change: background-color;
        transition: background-color 300ms;

        &_isActive {
            background-color: rgba(255, 255, 255, 1);
        }
    }
    
    &-Arrow {
        position: absolute;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        border-radius: 50%;
        background-color: var(--primary-dark-color);

        @include mobile {
            height: 25px;
            width: 25px;
        }

        &_isNext {
            inset-inline-end: calc(((100% - var(--content-wrapper-width)) / 2) + 30px) !important; //30px -> own Width

            @include mobile {
                inset-inline-end: 24px !important;
            }
        }

        &_isPrev {
            inset-inline-start: calc(((100% - var(--content-wrapper-width)) / 2) + 30px) !important; //30px -> own Width

            @include mobile {
                inset-inline-start: 24px !important;
            }
        }

        .ChevronIcon {
            width: 50%;
            height: 50%;
            fill: white;
        }
    }

    &-Wrapper {
        .pagebuilder-slide-wrapper {
            @include mobile {
                min-height: 500px !important;
            }

            .pagebuilder-overlay {
                max-width: var(--content-wrapper-width);
                padding-inline: 32px;
                margin-inline: auto;
                position: absolute;
                inset-inline-start: 50%;
                inset-block-start: 50%;
                width: 100%;
                transform: translate(-50%, -50%);

                @include mobile {
                    inset-block-start: initial;
                    inset-block-end: 0;
                    padding-inline: 24px;
                    transform: translate(-50%, -80px);
                }
    
                .pagebuilder-collage-content {
                    padding-inline: 100px;

                    @include mobile {
                        padding-inline: 36px;
                    }
    
                    [data-element="content"] {
                        h2 {
                            color: white;
                            font-size: 44px;
                            line-height: initial;

                            @include mobile {
                                font-size: 22px;
                            }
                        }
    
                        p {
                            color: white;
                            font-size: 17px;
                            font-weight: 600;

                            @include mobile {
                                font-size: 14px;
                            }
                        }
                    }
    
                    [data-element="button"] {
                        background-color: white;
                        color: var(--primary-base-color);
                        font-size: 20px;
                        padding: 10px 25px;
                        font-weight: 600;
                        margin-block-start: 15px;
                        cursor: pointer;

                        @include mobile {
                            font-size: 16px;
                            padding: 8px 16px;
                        }
                    }
                }
            }
        }
    }
}
