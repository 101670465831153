/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/media-extends';

.NoMatch {
    display: initial;

    &-Wrapper {
        display: grid; 
        grid-auto-columns: 1fr; 
        grid-template-columns: 240px 1fr; 
        grid-template-rows: 1fr; 
        gap: 0px 80px; 
        grid-template-areas: "MenuArea ContentArea"; 
        text-align: initial;
    }

    &-Menu {
        grid-area: MenuArea;
    }

    &-ContentArea {
        text-align: center;
        grid-area: ContentArea;
    }

    &-Text {
        font-size: 20px;
        margin-block-start: 20px;
    }
}
