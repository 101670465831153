/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/media-extends';

.Accordion {
    border-top: 1px solid rgba(0, 0, 0, .1);

    &:last-of-type {
        border-bottom: 1px solid rgba(0, 0, 0, .1);
    }

    &-ActionHandler {
        display: flex;
        height: 50px;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;

        &_expanded {
            border-bottom: 1px solid rgba(0, 0, 0, .1);
        }
    }

    &-Icon {
        svg {
            height: 20px;
            width: 20px;
        }
    }

    &-Content {
        transition: max-height 1s; 
        overflow: hidden;
        max-height: 0;
        
        &_expanded {
            padding-block: 20px;
            transition: max-height 1s; 
            max-height: 2000px;
        }
    }
}
