@font-face {
    font-family: 'Avenir';
    src: url("/style/fonts/2740E6_0_0.eot");
    src: url("/style/fonts/2740E6_0_0.eot?#iefix") format('embedded-opentype'),
        url("/style/fonts/2740E6_0_0.woff") format('woff'),
        url("/style/fonts/2740E6_0_0.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url("/style/fonts/2740FB_0_0.eot");
    src: url("/style/fonts/2740FB_0_0.eot?#iefix") format('embedded-opentype'),
        url("/style/fonts/2740FB_0_0.woff") format('woff'),
        url("/style/fonts/2740FB_0_0.ttf") format('truetype');
    font-weight: 600;
    font-style: normal;
}

body {
    font-family: Avenir, Helvetica, Arial, sans-serif;
}
