/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/media-extends';

/* stylelint-disable declaration-no-important */

@mixin twoColumnsDesktop {
    @media (max-width: 1300px) {
        @content;
    }
}

@mixin twoColumnsMobile {
    @media (max-width: 1000px) {
        @content;
    }
}

@mixin oneColumnsMobile {
    @media (max-width: 650px) {
        @content;
    }
}

main.CmsPage {
    margin-block-start: 1rem;
}

.CmsPage {
    &-Content {
        overflow: hidden;
        
        &_sideNavView {
            @include desktop {
                display: grid; 
                grid-auto-columns: 1fr; 
                grid-template-columns: 240px 1fr; 
                grid-template-rows: 1fr; 
                gap: 0px 80px; 
                grid-template-areas: "MenuArea ContentArea";
            }

            @include mobile {
                .CmsPage-Menu {
                    display: none;
                }
            }
        }
    }

    &-Menu {
        grid-area: MenuArea;
    }

    &-Wrapper {
        @include mobile {
            margin-block-start: 0 !important;
        }
    }

    &-ContentArea {
        grid-area: ContentArea;

        /* Anpassungen PageBuilder Home-Seite */

        [data-content-type="row"] {
            h1, h2, h3, h4 {
                @include mobile {
                    text-align: start;
                }
            }

            h1, h2 {
                font-weight: 600;
                font-size: 32px;
                line-height: 36px;
                color: var(--primary-base-color);

                @include mobile {
                    font-size: 21px;
                    line-height: 27px;
                }
            }

            &:last-of-type {
                >div {
                    display: grid !important;
                    grid-template-columns: repeat(3, 1fr);
                    gap: 20px;
    
                    @include twoColumnsDesktop {
                        grid-template-columns: repeat(2, 1fr);
                    }
    
                    @include mobile {
                        grid-template-columns: repeat(3, 1fr);
                    }
    
                    @include twoColumnsMobile {
                        grid-template-columns: repeat(2, 1fr);
                    }
    
                    @include oneColumnsMobile {
                        grid-template-columns: 1fr;
                    }
    
                    figure {
                        img {
                            object-fit: cover;
                            width: 100%;
                            height: 100% !important;
                        }

                        >a {
                            display: block;
                                                        
                            &::after {
                                content: '';
                                position: absolute;
                                inset-block-start: 0;
                                inset-inline-start: 0;
                                width: 100%;
                                height: 100%;
                                z-index: 1;
                                background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 70%, rgba(0,0,0,1) 100%)
                            }
                        }

                        figcaption {
                            position: absolute;
                            inset-block-end: 12px;
                            inset-inline-start: 21px;
                            color: var(--color-white);
                            font-weight: 600;
                            font-size: 18px;
                            // transform: translate(21px, -47px);
                            z-index: 2;
                            pointer-events: none;
                        }
                    }
                }
            }
        }
    }
}
