/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/media-extends';

:root {
    --header-nav-height: 110px;

    @include mobile-nav {
        --header-nav-height: 64px;
        --header-top-menu-height: 0px;
        --menu-total-height: 36px;
        --header-height: calc(var(--header-nav-height) + var(--header-top-menu-height) + var(--menu-total-height));
    }

    @include no-mobile-nav {
        --menu-total-height: 50px;
        --header-top-menu-height: 44px;
        --header-height: calc(var(--header-nav-height) + var(--header-top-menu-height) + var(--menu-total-height));
    }

    @include mobile {
        --header-nav-height: 64px;
        --header-top-menu-height: 0px;
        --menu-total-height: 36px;
    }
}

.Header {
    background-color: var(--primary-base-color);

    &-LogoWrapper {
        @include mobile-nav {
            --header-logo-height: 64px;

            grid-column: 2 / 3;
            position: static;
            justify-self: center;
            padding: 10px 0;
            max-width: none;
            opacity: 1;
        }
    }


    &-TopMenu {
        justify-content: flex-end;
        align-items: center;
        height: 20px;
        padding-block-start: 0;

        &Wrapper {
            background-color: var(--color-white);
            width: 100%;
            max-width: none;
            padding-block: 12px;
        }

        @include mobile-nav {
            display: none;
        }
    }

    &-Switcher {
        height: 20px;

        .StoreSwitcher-Title {
            line-height: 20px;
        }
    }

    &-Nav {
        @include no-mobile-nav {
            grid-template-columns: minmax(var(--header-logo-width), 1fr) minmax(auto, 480px) 1fr;
        }

        @include mobile-nav {
            display: grid;
            grid-template-columns: 50px auto 50px;
            padding-inline: 24px;
        }
        

        .Logo {
            background-color: transparent;
        }

        .SearchField {
            @include no-mobile-nav {
                margin-inline: 40px;
                margin-block: 0;
            }

            &-Input {
                height: 40px;
                background-color: transparent;
                border: 0;
                border-bottom: 1px solid rgba(255, 255, 255, .5);
                color: var(--color-white);
                caret-color: var(--color-white);
                padding-inline-start: 5px;

                &::placeholder {
                    color: var(--color-white);
                }
            }

            &-SearchIcon {
                inset-block-start: calc(50% - 6px);
            }

            &-SearchIcon, &-CloseIcon {
                inset-inline-end: 0;

                .SearchIcon, .CloseIcon {
                    fill: var(--color-white);
                }

                .SearchIcon {
                    height: 12px;
                }

                .CloseIcon {
                    &:hover {
                        fill: var(--primary-dark-color);
                    }
                }
            }
        }

        .CartIcon, .UserIcon, .HeartIcon {
            fill: var(--color-white);

            &:hover {
                fill: var(--primary-dark-color);
            }
        }
    }

    &-IconsWrapper {
        align-items: center;

        @include mobile-nav {
            justify-content: flex-end;

            .SearchField, .Header-WishlistLink, .Header-MyAccountContainer {
                display: none;
            }
        }
    }

    &-UspWrapper {
        display: flex;
        align-items: center;
        max-width: none;
        background: linear-gradient(90deg, #ca2329 0%, #8b0005 100%);

        @include no-mobile-nav {
            height: 50px;
        }

        @include mobile-nav {
            height: 36px;
        }
    }

    &-Usp {
        max-width: var(--content-wrapper-width);
        width: 100%;
        margin: 0 auto;
        padding-inline: 14px;

        @include no-mobile-nav {
            display: grid;
            grid-template-columns: 150px auto 150px;
            padding-inline: 32px;
        }

        .Link-Wrapper {
            text-align: end;

            .Link-AllProducts {
                color: var(--color-white);
                opacity: .8;
                font-weight: normal;
                display: inline-flex;
                align-items: center;
                height: 20px;

                &:hover {
                    text-decoration: underline;
                }

                .ChevronIcon {
                    fill: var(--color-white);
                    height: 12px;
                    width: 12px;
                    margin-inline-start: 5px;
                }
            }
        }

        .CmsBlock-Wrapper {
            @include no-mobile-nav {
                grid-column: 2 / 3;
            }
        }
    }

    &-UspList {
        @include no-mobile-nav {
            display: flex;
            justify-content: space-around;
        }

        @include mobile-nav {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
        }
        
        li {
            display: flex;
            align-items: center;
            margin: 0;

            @include mobile-nav {
                grid-column: 1 / 2;
                grid-row: 1 / 2;
                justify-self: center;
                opacity: 0;
                animation-name: usp-mobile;
                animation-duration: 15s;
                animation-iteration-count: infinite;
                animation-fill-mode: both;

                &:nth-child(1) {
                    animation-delay: 0s;
                }

                &:nth-child(2) {
                    animation-delay: 5s;
                }

                &:nth-child(3) {
                    animation-delay: 10s;
                }
            }

            &::before {
                display: none;
            }

            span {
                margin-inline-start: 10px;
                color: var(--color-white);
                opacity: .8;
            }

            svg {
                height: 16px;
                opacity: .8;
            }
        }
    }

    &-Branding {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include desktop {
            grid-column: 2 / 3;
            margin-block-start: -42px;
        }

        @include mobile-nav {
            display: none;
        }
    }

    &-Link {
        font-weight: normal;
        color: var(--color-black);
        margin-inline-start: 30px;
        font-size: 12px;
    }

    &-Button {
        &_type_minicart {
            margin-inline-start: 20px;
        }
    }

    &-MyAccountContainer {
        margin-inline-start: 20px;
    }

    &-WishlistLink {
        margin-inline-start: 0;

        @include desktop {
            height: 24px;
        }
    }

    &-MinicartItemCount {
        background-color: var(--primary-dark-color);
        box-shadow: 0px 0px 0px 1px var(--primary-base-color);
        padding: 3px 2px 1px;
        inset-block-start: auto;
        inset-block-end: -6px;
        inset-inline-end: -10px;
    }

    &-WishlistItemCount {
        background: var(--primary-dark-color);
        border-radius: 8px;
        color: var(--color-white);
        height: 17px;
        padding: 3px 2px 1px;
        position: absolute;
        min-width: 17px;
        display: flex;
        font-size: 11px;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        box-shadow: 0px 0px 0px 1px var(--primary-base-color);

        @include desktop {
            inset-block-start: auto;
            inset-block-end: -6px;
            inset-inline-end: -8px;
        }
    }

    &_isCheckout {
        .Header {
            &-Nav {
                display: grid;
            }
            
            &-IconsWrapper {
                width: auto;
                justify-content: flex-end;
            }
        }
    }
}

@keyframes usp-mobile {
    0% {
        opacity: 0;
    }
    
    3% {
        opacity: 1;
    }

    30% {
        opacity: 1;
    }

    33% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}
