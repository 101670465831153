/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/media-extends';

/* stylelint-disable declaration-no-important */

.CookiePopup {
    background-color: var(--color-white);
    transform: none;
    inset-block-end: 0;
    inset-inline: 0 !important;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
    margin: 2rem 2rem 1.5rem;
    position: fixed;
    z-index: 2000;
    padding: 1.3rem;
    opacity: 1;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 21px;

    &-Content {
        color: #333;
        font-size: 17px;
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: initial;
    }

    &-Heading {
        color: var(--primary-base-color);
        font-size: 21px;
        font-weight: bold;
    }

    &-ButtonWrapper {
        display: flex;
        align-items: center;
        gap: 21px;
    }

    &-AcceptButton,
    &-Link {
        font-size: 17px;
        padding: 0.6rem 1rem;
        font-weight: bold;
        border-radius: 0.25rem;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
        cursor: pointer;
    }

    &-AcceptButton {
        background-color: #404040;
        color: white;
    }

    &-Link {
        background-color: white;
        color: #404040;

        &:hover {
            color: #404040;  
        }
    }
}
