/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/media-extends';

.Dropdown {
    position: relative;
    height: 40px;

    &-Toggle {
        width: 100%;
        border: 1px solid var(--primary-base-color);
        height: 100%;
        color: var(--primary-base-color);
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-inline: 15px;
        cursor: pointer;

        svg {
            fill: var(--primary-base-color);
        }
    }

    &-Heading {
        padding-block-start: 4px;
    }

    &-Options {
        display: none;
        position: absolute;
        inset-block-start: 100%;
        inset-inline-start: 0;
        width: 100%;
        background-color: white;
        border-style: solid;
        border-width: 0 1px 1px 1px;
        border-color: var(--primary-base-color);
        padding: 10px 15px;
        z-index: 10;

        .ProductAttributeValue-Text {
            label {
                &:first-of-type {
                    .input-control {
                        @include mobile {
                            margin-block-start: -3px;
                        }

                        @include desktop {
                            margin-block-start: 2px;
                        }
                    }
                }
            }
        }
    }

    &_isOpen {
        .Dropdown {
            &-Options {
                display: block;
            }
        }
    }
}
