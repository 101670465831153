/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/media-extends';


.CircularProgress {
    position: relative;

    &-Svg {
        transform: rotate(90deg) scaleX(-1);

        &Track {
            stroke: #eee;
            fill: transparent;
        }

        &Indicator {
            animation-duration: 1000;
            stroke: var(--primary-base-color);
            fill: transparent;
            transition: stroke-dashoffset .5s linear;

            &_isSpinnerMode {
                animation: spinner .75s linear infinite;
                transform-origin: center;
            }
        }
    }

    &-Label {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 15px 10px;

        &Price {
            color: var(--primary-base-color);
            font-size: 20px;
            font-weight: 600;
            text-align: center;
        }

        &Text {
            font-weight: 600;
            font-size: 12px;
            text-align: center;

            &Free {
                font-size: 20px;
                text-align: center;
                font-weight: 600;
                margin-block-start: -10px;
            }
        }
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}
