/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

$white: #fff;
$black: #0a0903;
$default-primary-base-color: #e52329;
$default-primary-dark-color: #760d11; // darken($default-primary-base-color, 15%);
$default-primary-light-color: lighten($default-primary-base-color, 15%);
$default-secondary-base-color: #eee;
$default-secondary-dark-color: darken($default-secondary-base-color, 15%);
$default-secondary-light-color: lighten($default-secondary-base-color, 15%);
$font-muli: 'Muli', sans-serif;
$font-standard-size: 62.5%;
