/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/media-extends';

.Footer {
    --hollow-button-border: var(--color-white);
    --hollow-button-color: var(--color-white);
    --h3-font-size: 16px;

    background: var(--primary-base-color) url("/style/assets/images/tiled_waffle.jpeg") bottom repeat-x;

    @include mobile {
        margin-block-end: 0;
    }

    &-Content, &-CopyrightContentWrapper {
        background-color: transparent;
    }

    &-Column {
        &:not(:last-of-type) {
            max-width: none;
        }

        &Item {
            &_type_image {
                width: 30px;
                height: 30px;
                border: 0;
                
                .Footer-ColumnItemImage {
                    img {
                        /* stylelint-disable-next-line */
                        width: auto !important;
                    }
                }
            }
        }

        &Title {
            margin-block-start: 0;
            margin-block-end: 18px;
            color: var(--color-white);
            text-transform: uppercase;
            font-weight: 300;
        }

        &Content {
            &_direction_horizontal {
                justify-content: flex-end;
            }

            h3 {
                margin-block-start: 0;
                color: var(--color-white);
                text-transform: uppercase;
                font-weight: 300;
            }

            ul {
                color: var(--color-white);

                li {
                    padding-inline-start: 12px;

                    &::before {
                        inset-inline-start: 0;
                        inset-block-start: auto;
                        color: var(--color-white);
                        font-size: 10px;
                    }

                    a {
                        color: var(--color-white);
                        font-weight: 300;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            .pagebuilder-button-primary {
                @include button;

                padding: 2px 12px 0px;
                height: 36px;
                border-radius: 0;
                border: 1px solid var(--color-white);
                text-transform: none;
                font-size: 14px;
                background-color: transparent;

                &:not([disabled]) {
                    &:hover {
                        padding: 2px 12px 0px;
                        height: 36px;
                        border-radius: 0;
                        border: 1px solid var(--color-white);
                        background-color: var(--color-white);
                        color: var(--primary-base-color);
                    }
                }
            }
        }

        @include desktop {
            &:not(:last-of-type) {
                padding-inline-end: 0;
            }
        }
    }

    &-Columns {
        @include desktop {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            gap: 30px;
        }
    }

    &-PaymentMethods {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: auto;
        gap: 10px;

        &Item {
            display: flex;
        }
    }

    .Footer-CopyrightContent {
        padding: 30px 32px;
        justify-content: flex-start;
        
        p {
            color: var(--color-white);
            font-size: 12px;
            
            a {
                color: var(--color-white);
                font-weight: 300;
                margin: 0 10px;
                font-size: 12px;

                &:first-of-type {
                    margin-inline-start: 20px;
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
