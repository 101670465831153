@mixin mobile-nav {
    @media (max-width: 1180px) { // 1180
        @content;
    }
}

@mixin no-mobile-nav {
    @media (min-width: 1181px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 1181px) {
        @content;
    }
}

@mixin wide-desktop {
    @media (min-width: 1380px) {
        @content;
    }
}

@mixin narrow-desktop {
    @media (min-width: 1181px)
        and (max-width: 1280px) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: 1180px) {
        @content;
    }
}

@mixin breakPointMax($valueInPx) {
    @media screen and (max-width: $valueInPx) {
        @content;
    }
}

@mixin breakPointMin($valueInPx) {
    @media screen and (min-width: $valueInPx) {
        @content;
    }
}
