/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/media-extends';

.LineProgress {
    position: relative;
    width: 100%;
    background-color: #eee;
    padding: 20px 50px;

    @include breakPointMax(450px) {
        padding-inline: 8px;
    }

    &-Svg {
        width: 100%;
        height: 30px;
        padding: 0 20px;
        overflow: visible;
        stroke-linecap: round;

        &Track {
            stroke: var(--color-white);
            fill: transparent;
        }

        &Indicator {
            stroke: var(--primary-base-color);
            fill: transparent;
            stroke-dasharray: 1180;
            stroke-dashoffset: 1180;
            animation: dash 2s linear forwards;
        }
    }

    &-Label {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 600;
        color: var(--color-black);
        text-align: center;
        gap: 5px
    }
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}
