/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/media-extends';

/* stylelint-disable declaration-no-important */

.HomePage {
    .CmsPage {
        @include mobile {
            margin-block-start: 0 !important;
        }
    }

    .CmsPage-ContentWrapper {
        padding-block-start: 50px;

        h3 {
            &:first-of-type {
                padding-block-start: 0;
                margin-block-start: 0;
            }
        }
    }

    h3 {
        font-size: 37px;
        line-height: 1;
        color: var(--primary-base-color);
        margin-block-end: 24px;
    }
}
