/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/media-extends';

.SearchItem {
    &-Title {
        color: var(--primary-base-color);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 600;
    }

    &-Price {
        @include mobile {
            margin-block-start: 5px;
        }

        .ProductPrice {
            &-HighPrice {
                font-size: 12px;

                @include mobile {
                    margin-block-start: 0;
                }
            }

            &-Price {
                font-size: 14px;

                span {
                    margin-block-start: 0;
                }
            }

            &-PriceValue {
                font-size: 14px;
            }
        }
    }
}
