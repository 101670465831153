/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/media-extends';

.SearchOverlay {
    &-Results {
        @include desktop {
            min-width: 410px;
            inset-inline-end: 0;
        }

        @include mobile {
            position: absolute;
            max-height: calc(100vh - 150px);
            overflow-y: auto;
        }
    }
}
