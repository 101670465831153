/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/media-extends';

.MobileSidebar {
    --category-nav-item-border-color: rgba(255, 255, 255, .2);

    @include no-mobile-nav {
        display: none;
    }

    @include mobile-nav {
        display: none;
        position: fixed;
        z-index: 1000;
        inset: 0;

        &-enter {
            display: block;
            background-color: rgba(0, 0, 0, 0);

            .MobileSidebar-Container {
                transform: translateX(-100%);
            }
        }

        &-enter-active, &-enter-done {
            display: block;
            background-color: rgba(0, 0, 0, .7);
            transition: all .3s ease-in-out;

            .MobileSidebar-Container {
                transform: translateX(0%);
            }
        }

        &-exit {
            display: block;
            background-color: rgba(0, 0, 0, 0.7);

            .MobileSidebar-Container {
                transform: translateX(0%);
            }
        }

        &-exit-active {
            display: block;
            background-color: rgba(0, 0, 0, 0);
            transition: all .3s ease-in-out;

            .MobileSidebar-Container {
                transform: translateX(-100%);
            }
        }

        &-exit-done {
            display: none;

            .MobileSidebar-Container {
                transform: translateX(-100%);
            }
        }

        &-Container {
            height: 100%;
            width: calc(100% - 50px);
            max-width: 350px;
            background: linear-gradient(90deg, #ca2329 0%, #8b0005 100%);
            transition: all .3s ease-in-out;
        }

        &-Header {
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-inline: 20px 30px;
            margin-block-end: 20px;

            .StoreSwitcher {
                border: 0;
                margin: 0;

                .FieldSelect {
                    &-Select {
                        background-color: transparent;
                        padding-block: 12px 8px;
                        padding-inline-end: 25px;
                        color: var(--color-white);
                    }

                    svg {
                        fill: var(--color-white);
                    }
                }

                &-Title {
                    color: var(--color-white);
                    font-size: 14px;
                    
                    svg {
                        fill: var(--color-white);
                    }
                }
            }
        }

        &-ToggleClose {
            height: 36px;
            width: 36px;

            .CloseIcon {
                height: 36px;
                width: 36px;
            }
        }

        &-Links {
            display: flex;
            flex-direction: column;
            padding: 40px 25px;

            .CmsBlock-Wrapper {
                > div {
                    display: flex;
                    flex-direction: column;
                }
            }

            a {
                color: var(--color-white);
                padding: 5px 0;
                margin: 2px 0;

                svg {
                    fill: var(--color-white);
                    height: 14px;
                    width: 14px;
                    margin-inline-end: 15px;
                    margin-block-end: -1px;
                }

                &:active {
                    color: var(--primary-dark-color);

                    svg {
                        fill: var(--primary-dark-color);
                    }
                }
            }
        }

        &-Copyright {
            padding: 0 25px;

            p {
                color: var(--color-white);
                font-size: 12px;
                
                a {
                    color: var(--color-white);
                    font-weight: 300;
                    margin: 0 10px;
                    font-size: 12px;
    
                    &:first-of-type {
                        margin-inline-start: 20px;
                    }
    
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        &-Content {
            .SearchField {
                width: 100%;
                padding-inline: 25px;
                margin-block-end: 40px;
            }

            .CategoryMenu {
                width: 100%;

                &-Heading {
                    display: none;
                }

                &-Item {
                    padding-inline: 25px;
                }

                &-Link {
                    color: var(--color-white);
                    font-size: 18px;

                    &:hover {
                        color: var(--color-white);
                    }
            
                    &:active {
                        color: var(--primary-dark-color);
                    }
                }
                
                &-ChevronIcon {
                    svg {
                        fill: var(--color-white);
                    }

                    &:hover {
                        svg {
                            fill: var(--color-white);
                        }
                    }
            
                    &:active {
                        svg {
                            fill: var(--primary-dark-color);
                        }
                    }
                }
            }
        }
    }

    &-ToggleOpen {
        svg {
            height: 30px;
            width: 30px;
        }
    }

    &-ToggleOpen, &-ToggleClose {
        justify-self: start;
        display: flex;

        .MenuIcon, .CloseIcon {
            transition: all .15s ease-in-out;
            fill: var(--color-white);
        }

        &:hover {
            .MenuIcon, .CloseIcon {
                fill: var(--color-white);
            }
        }

        &:active {
            .MenuIcon, .CloseIcon {
                fill: var(--primary-dark-color);
            }
        }

        @include no-mobile-nav {
            display: none;
        }
    }
}
