/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/media-extends';

.DeliveryModal {
    font-size: 16px;

    .Popup-Content {
        background-color: var(--primary-base-color);
        color: white;

        .Popup-Heading {
            color: white;
        }

        .Popup-CloseBtn {
            .CloseIcon {
                fill: white;
            }
        }
    }
}
