/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/media-extends';

.HeartIcon {
    @include icon-black-hover-effect;
    @include rtl-icon;

    cursor: pointer;
    width: 24px;
    height: 24px;

    &.-stroke {
        height: 18px;
        width: 18px;
    }

    .stroke {
        fill: var(--primary-base-color);
    }

    &_isStrokeIcon {
        .border {
            fill: var(--primary-base-color);
        }

        .inner {
            fill: transparent;
        }

        &:hover {
            .border {
                fill: var(--primary-dark-color);
            }

            .inner {
                fill: var(--primary-dark-color);
                stroke: var(--primary-dark-color);
                stroke-width: 20px;
            }
        }
    }


    &_isActive {
        .border {
            fill: var(--primary-dark-color);
        }

        .inner {
            fill: var(--primary-dark-color);
            stroke: var(--primary-dark-color);
            stroke-width: 20px;
        }
    }
}
