/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/media-extends';

.NotificationList {
    position: fixed;
    z-index: 800;
    // inset-block-start: calc(var(--header-height) + var(--breadcrumbs-height) + 20px);
    // inset-inline-end: 30px;
    max-width: 550px;
    list-style: none;
    inset-block-start: var(--header-height);
    inset-inline-end: calc(((100vw - var(--content-wrapper-width)) / 2) + 32px);

    @include desktop {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
    }

    @include mobile {
        overflow: hidden;
        max-width: 100vw;
        position: fixed;
        width: 100%;
        inset-block-start: var(--header-total-height);
        inset-inline-end: 0;
    }
}
